import * as React from "react"
import { graphql } from "gatsby"

import Layout from "./layout2022"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const JournalIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title="220706" />
      <div className="position-relative">
        <div className="zindex-minus position-absolute top-0 end-0">
          <StaticImage
            formats={["auto", "webp", "avif"]}
            src="../../../images/journal/plant-bird-01.png"
            width={210}
            quality={95}
            />
        </div>

        <div>
          <div className="w-75 pt-5">
            <p>I just came up with the idea of creating new digital journal on my site, somehow with mixing the analog taste such as collage (gonna be struggles with css) or drawing. I'll post here more casual writing than my main blog, so would be great using here like a digital travel journal.</p>
            <h2 className="font-title-sub pt-5">Unforgettable food in Hokkaido</h2>
            <p>Thinking of the first post here, I'll write about unforgettable delicious food in Sapporo in Hokkaido, a city in the north part of Japan, since I traveled around the city for about 2 weeks last month and was amazing trip!</p>
          </div>

          <div className="row text-end py-3">
            <StaticImage
              formats={["auto", "webp", "avif"]}
              src="../../../images/journal/2022/kanako-curry.jpg"
              quality={95}
              />
            <div className="col-2"></div>
            <div className="col-10">
              <h3 className="font-title-sub">Soup Curry<br/>from Kanako's Soup Curry Shop</h3>
              <p>As you know soup curry is one of famous food in Sapporo, there're many curry shops in the city. I just happened to find this place by scrolling google map, but this shop's curry is amazing. Since the staff prepares a dish after ordered, so it takes a long time until you get but this curry is worth waiting.</p>
            </div>
          </div>

          <div className="row py-4">
            <div className="col-10">
            <h3 className="font-title-sub">Ramen from Ebisoba Ichigen</h3>
              <p>One of things to do in Hokkaido is eating local ramen. This time I went to Ichigen main branch, 10 min by foot from the hotel.</p>
              <p>The ramen consists of mixing shrimp base soup and pork broth soup, and you can choose the ratio of soups and types of noodles (thickness). The soup is very rich but not that heavy so you can finish all easily! At the end, some people add rice into the soup and eat like risotto (I didn't have enough room though).</p>
            </div>
            <div className="col-2">
            </div>
            <div className="text-end px-4">
              <StaticImage
              formats={["auto", "webp", "avif"]}
              src="../../../images/journal/shrimp.png"
              width={300}
              quality={95}
              />
            </div>
          </div>

          <div className="row text-center py-4">
            <div className="col-1"></div>
            <div className="col-10">
              <h3 className="font-title-sub">Pancake from Cafe Blue</h3>
              <p>I really wanted to visit this place. The cafe was almost full even though I visited on weekdays, but soon I knew the reason.</p>
              <StaticImage
                formats={["auto", "webp", "avif"]}
                src="../../../images/journal/2022/cafe-blue.jpg"
                quality={95}
                />
              <p className="py-3">I ordered a pancake plate with big bacon, and it was really good. I've never had such fluffy pancakes ever! And the pancake has strong egg taste and also goes with the saltiness of the bacon. I don't wanna tell anyone this place in fact so secretly write here...</p>
            </div>
            <div className="col-1"></div>
            <div className="text-start">
                <StaticImage
                formats={["auto", "webp", "avif"]}
                src="../../../images/journal/leopard.png"
                width={400}
                quality={95}
                />
              </div>
          </div>

        </div>
      </div>
    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
